const SET_NAME = 'scratch-gui/connection-modal/setName';
const WEB_Device = 'webDevice';
const CLEAR_NAME = 'scratch-gui/connection-modal/clearName';
const SET_REALTIME_PROTOCAL_CONNECTION = 'scratch-gui/connection-modal/setRealtimeConnection';
const SET_LIST_ALL = 'scratch-gui/connection-modal/setListAll';

const initialState = {
    peripheralName: null,
    realtimeConnection: false,
    isListAll: false
};
let per_ipheralName = 'COM';

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    
    
    switch (action.modal) {
    case WEB_Device:
        // console.log("SET_REALTIME_PROTOCAL_CONNECTION--------")
        // console.log("reducer--------",state,action,action.type,action.peripheralName)
        if (state.peripheralName){
            return Object.assign({}, state, {
                peripheralName: null,
                realtimeConnection: false
            });
        }else{
            return Object.assign({}, state, {
                peripheralName: per_ipheralName,
                realtimeConnection: true
            });
        }
    default:
        return state;
    }
};

const setConnectionModalPeripheralName = function (peripheralName) {
    return {
        type: SET_NAME,
        peripheralName: peripheralName
    };
};

const clearConnectionModalPeripheralName = function () {
    return {
        type: CLEAR_NAME
    };
};

const setRealtimeConnection = function (isConnected) {
    return {
        type: SET_REALTIME_PROTOCAL_CONNECTION,
        isConnected: isConnected
    };
};

const setListAll = function (isListAll) {
    return {
        type: SET_LIST_ALL,
        isListAll: isListAll
    };
};

export {
    reducer as default,
    initialState as connectionModalInitialState,
    setConnectionModalPeripheralName,
    clearConnectionModalPeripheralName,
    setRealtimeConnection,
    setListAll
};
