import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import VM from 'openblock-vm';

import { setStageSize } from '../reducers/stage-size';
import { STAGE_SIZE_MODES } from '../lib/layout-constants';
import { openUploadProgress } from '../reducers/modals';
import { showAlertWithTimeout } from '../reducers/alerts';

import HardwareHeaderComponent from '../components/hardware-header/hardware-header.jsx';



class HardwareHeader extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleUpload'
        ]);
    }


    handleUpload() {
        // console.log(this.props.peripheralName,this.props.deviceId, this.props.codeEditorValue)
        var fileContent = this.props.codeEditorValue;
        var file = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
        window.showDirectoryPicker().then(value => { 
            var iter = value.entries()
            var flag = false
            var promises = []
            iter.next().then(function next(result) {
                if (result.done) {
                    // 迭代完成
                    return Promise.all(promises);
                }
            
                promises.push(Promise.resolve(result.value).then(function(item) {
                    let it = item[1]
                    if (it.name == 'main.py' || it.name == 'code.py'){
                        console.log(it);
                        it.createWritable().then(write =>{
                            write.write(file).then(write_w =>{
                                write.close().then(write_c =>{
                                })
                            })
                        })
                    }
                    return iter.next().then(next);
                }));
            });
        }).catch((e) => {console.log(e)});
        // FileSaver.saveAs(file, 'example.txt');
        console.log(file, this.props.peripheralName, this.props.deviceId, this.props.codeEditorValue)
        // if (this.props.peripheralName) {
        //     //上传代码
        //     const blocks = document.querySelector('.blocklyWorkspace .blocklyBlockCanvas');
        //     if (blocks.getBBox().height === 0) {
        //         this.props.onWorkspaceIsEmpty();
        //     } else {
        //         this.props.vm.uploadToPeripheral(this.props.deviceId, this.props.codeEditorValue);
        //         this.props.onOpenUploadProgress();
        //     }
        // } else {
        //     this.props.onNoPeripheralIsConnected();
        // }
    }


    render() {
        const {
            ...props
        } = this.props;
        return (
            <HardwareHeaderComponent
                onUpload={this.handleUpload}
                {...props}
            />
        );
    }

}

HardwareHeader.propTypes = {
    codeEditorValue: PropTypes.string,
    deviceId: PropTypes.string,
    onNoPeripheralIsConnected: PropTypes.func.isRequired,
    onOpenUploadProgress: PropTypes.func,
    onWorkspaceIsEmpty: PropTypes.func.isRequired,
    peripheralName: PropTypes.string,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    codeEditorValue: state.scratchGui.code.codeEditorValue,
    deviceId: state.scratchGui.device.deviceId,
    peripheralName: state.scratchGui.connectionModal.peripheralName,
    stageSizeMode: state.scratchGui.stageSize.stageSize
});

const mapDispatchToProps = dispatch => ({
    onNoPeripheralIsConnected: () => showAlertWithTimeout(dispatch, 'connectAPeripheralFirst'),
    onSetStageLarge: () => dispatch(setStageSize(STAGE_SIZE_MODES.large)),
    onSetStageSmall: () => dispatch(setStageSize(STAGE_SIZE_MODES.small)),
    onSetStageHide: () => dispatch(setStageSize(STAGE_SIZE_MODES.hide)),
    onOpenUploadProgress: () => dispatch(openUploadProgress()),
    onWorkspaceIsEmpty: () => showAlertWithTimeout(dispatch, 'workspaceIsEmpty')
});





export default compose(
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(HardwareHeader);
